import React from "react";

function HowTo() {
    return (
        <div className={"how-to-content"}>
            <section className={"how-to-section"}>
            <h2>How it works</h2>
            <p>Create your own tile sheet that can be used in many Guess Who playing boards.</p>
                <p>You need to have a Guess Who board to populate with your new cards</p>
            </section>
            <section className={"how-to-section invert"}>
            <h3 className="section-title">Board Size Guide</h3>

            <p>This creator can create face cards that will fit most versions of the game</p>
            <section className={"board-select-section"} id={"board-size"}>
                <div className={"board-select-tile"}>
                    <p><strong>Small</strong></p>
                    <p>The face cards measure 1.126in by 1.38in</p>
                    <div className={"board-example-container"}>
                    <img alt="Guess Logo" src={"/guesswhosmall.png"} className={"board-example"} />
                    </div>
                    <p>For example, this <a href={"https://amzn.to/3f5JDxX"}>version on Amazon</a> uses Small tiles</p>
                </div>
                <div className={"board-select-tile"}>
                    <p><strong>Medium</strong></p>
                    <p>The face cards measure 1.125in by 1.687in</p>
                    <div className={"board-example-container"}>

                    <img alt="Guess Logo" src={"/guesswhomedium.png"} className={"board-example"} />
                    </div>

                    <p>For example, this <a href={"https://amzn.to/2EMKd5P"}>version on Amazon</a> uses Medium tiles</p>
                </div>
                <div className={"board-select-tile"}>
                    <p><strong>Large</strong></p>
                    <p>The face cards measure 1.125in by 2in</p>
                    <div className={"board-example-container"}>

                    <img alt="Guess Logo" src={"/guesswholarge.png"} className={"board-example"} />
                    </div>

                    <p>For example, this <a href={"https://amzn.to/3lEBPWm"}>version on Amazon</a> uses Large tiles</p>

                </div>

            </section>
            <p>Please measure your existing face cards first to make sure which size card you need!</p>
            </section>
            <section className={"how-to-section"} id={"how-to"}>
            <h3 className="section-title">How to use</h3>
            <p><strong>Step 1: Upload photos</strong></p>
            <p>Click in the box or drag photos in to upload them. We currently support up to 50 photos at a time.</p>
            <p><strong>Step 2: Select your board size</strong></p>
            <p>Select your board size according to the information above</p>
            <p><strong>Step 3: Crop and name your characters</strong></p>
            <p>Enter a name for your character</p>
            <p>Click and drag on the original photo to crop, a preview of the resulting card is shown on the right</p>
            <p>Hit continue when you like what you see</p>
            <p><strong>Step 4: Crop the player card</strong></p>
            <p>Player cards are set to a size 2in x 3in regardless of board size</p>
            <p><strong>Step 5: Download & Print</strong></p>
            <p>Print your tile sheets on letter size paper, we recommend printing on a heavy card stock available at any commercial printers</p>
            </section>
        </div>
    );
}

export default HowTo;
