import React from "react";
import {Text, View, StyleSheet, Image } from '@react-pdf/renderer';


const styles = StyleSheet.create({
    tile: {
        width: '1.125in',
        height: '1.687in',
        margin: 15,
        marginLeft: 10,
        backgroundSize: 'cover',
        position: 'relative',
        display: 'inline-flex'
    },
    image: {
        width: '1.125in',
        height: '1.687in',
        position: 'absolute',
        top: 20,
        left:20,
    },


    tileSmall: {
        width: '1.26in',
        height: '1.38in',
        margin: 15,
        marginLeft: 5,
        marginBottom: 40,
        backgroundSize: 'cover',
        position: 'relative',
        display: 'inline-flex'
    },
    imageSmall: {
        width: '1.26in',
        height: '1.38in',
        position: 'absolute',
        top: 20,
        left:20,
    },
    labelSmall: {
        color: 'white',
        backgroundColor: '#231f20',
        width: '100%',
        marginLeft:20,
        marginTop: 97,
        height: 23,
        textAlign: 'center',
        padding: 2,
    },




    tileLarge: {
        width: '1.125in',
        height: '2in',
        margin: 15,
        marginLeft: 10,
        marginBottom: 30,
        backgroundSize: 'cover',
        position: 'relative',
        display: 'inline-flex'
    },
    imageLarge: {
        width: '1.125in',
        height: '2in',
        position: 'absolute',
        top: 20,
        left:20,
    },



    label: {
        color: 'white',
        backgroundColor: '#231f20',
        width: '100%',
        marginLeft:20,
        marginTop: 120,
        height: 23,
        textAlign: 'center',
        padding: 2,
    },
    text: {
        color: 'white',
        fontSize: 5,
        display: 'flex',
        fontFamily: 'obelixproregular',
        flex:1,
        flexDirection:'row',
        width: '100%',
    }
});


function SmallTile(props) {
    switch (props.size) {
        case "small":
            console.log("size small")
            return (
                <View style={styles.tileSmall}>
                    <Image className="image-tile" style={styles.imageSmall}
                           src={props.image}/>
                    <View style={styles.labelSmall}
                          className="image-title"><Text style={styles.text}>{props.name}</Text></View>
                </View>
            );
        case "large":
            console.log("size large")
            return (
                <View style={styles.tileLarge}>
                    <Image className="image-tile" style={styles.imageLarge}
                           src={props.image}/>
                    <View style={styles.label}
                          className="image-title"><Text style={styles.text}>{props.name}</Text></View>
                </View>
            );
        default:
            console.log("size default")
            return (
                <View style={styles.tile}>
                    <Image className="image-tile" style={styles.image}
                           src={props.image}/>
                    <View style={styles.label}
                          className="image-title"><Text style={styles.text}>{props.name}</Text></View>
                </View>
            );

    }
}

export default SmallTile;
