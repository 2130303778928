import React, { Component } from "react";
// import './css/component/_uploadBox.scss';
import Dropzone from "react-dropzone";
import EditPhotos from "./editPhotos";
import Slide from '@material-ui/core/Slide';
import Button from 'react-bootstrap/Button';
import { HashLink as Link } from 'react-router-hash-link';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

class UploadFiles extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedFile: [],
            uploadComplete: false,
            uploadPending: false,
            fileNames: [],
            showList: false
        }
    }


    handleDrop = acceptedFiles => {
        this.setState({
            uploadPending: true,
            fileNames: acceptedFiles.map(file => file.name),
            selectedFile:acceptedFiles
        });
        window.scrollTo(0,document.body.scrollHeight);
    }


    showList() {
        this.setState({showList: !this.state.showList})
    }


    async uploadFiles() {
        let fileArray = [];

        for(let x = 0; x<this.state.selectedFile.length; x++) {
            console.log(await toBase64(this.state.selectedFile[x]))
            fileArray.push({name:this.state.selectedFile[x].name, image: await toBase64(this.state.selectedFile[x])});
        }
        this.setState({dataArray: fileArray, uploadComplete:true})
    }

    render() {
        if (!this.state.uploadComplete) {
            return (
                <div className="container">
                    {/*<h2>Tile Sheet Creator</h2>*/}
                    {/*<p>Create your custom tile sheet in just four steps.</p>*/}
                    {/*<ul className="step-list">*/}
                    {/*    <li>Step 1: Upload files</li>*/}
                    {/*    <li>Step 2: Crop face cards</li>*/}
                    {/*    <li>Step 3: Crop player cards</li>*/}
                    {/*    <li>Step 4: Download your boards!</li>*/}
                    {/*</ul>*/}
                    <h1 className="section-title">Guess Creator</h1>
                    <p>Upload photos to make a custom tile sheet that will fit in most Guess Who playing boards</p>
                    <Dropzone className="section-title" onDrop={this.handleDrop}>
                        {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps({ className: "dropzone" })}>
                                {!this.state.uploadComplete &&
                                <input className="file-upload-btn" type="file"  {...getInputProps()} />
                                }

                                <input {...getInputProps()} />
                                <h3>Click Here or Drag files to upload</h3>
                            </div>
                        )}
                    </Dropzone>
                    <div>
                        {this.state.fileNames.length !== 0 && (
                            <p onClick={this.showList.bind(this)}>{this.state.fileNames.length} files selected</p>
                        )}

                        {this.state.showList && (
                            <div>
                            <strong>Files:</strong>

                            <ul>
                            {this.state.fileNames.map(fileName => (
                                <li key={fileName}>{fileName}</li>
                            ))}

                        </ul>
                            </div>
                        )}
                        {this.state.uploadPending &&
                        // <button className="btn-primary  file-upload-btn" type="button"
                        //         onClick={this.uploadFiles.bind(this)}>confirm
                        // </button>
                        <Button variant="success" className="btn-primary  file-upload-btn" onClick={this.uploadFiles.bind(this)}>Continue <FontAwesomeIcon icon="arrow-circle-right" size="2x" />
                        </Button>
                        }
                    </div>


                </div>
            );
        } else {
            return (<Slide direction="left" in={true} out={true} mountOnEnter unmountOnExit><EditPhotos images={this.state.dataArray}/></Slide>)

        }
    }
}

export default UploadFiles;


