import React, {PureComponent} from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
// import './css/pages/_editPhotos.scss';
import DownloadPage from "./downloadPage";
import Button from "react-bootstrap/Button";

class EditPhotos extends PureComponent {
    state = {
        src: this.props.images,
        crop: {
            unit: '%',
            width: 30,
            aspect: 1.125 / 1.687,
        },
        index: 0,
        size: 'medium',
        type: "face",
        nameArray: [...this.props.images].map(image => {
            return image.name.replace(/\.[^/.]+$/, "")
        }),
        showDownload: false,
    };

    faceCardArray = [];
    playerCardArray = [];

    onImageLoaded = image => {
        this.imageRef = image;
    };

    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop) => {
        this.setState({crop});
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                'newFile.jpeg'
            );
            this.setState({croppedImageUrl});
        }
    }

    resetCrop(size) {
        let newCrop;
        switch (size) {
            case "small":
                newCrop = {
                    unit: '%',
                    width: 30,
                    aspect: 1.26 / 1.38,
                }
                break;
            case "large":
                newCrop = {
                    unit: '%',
                    width: 30,
                    aspect: 1.125 / 2,
                }
                break;
            default:
                newCrop = {
                    unit: '%',
                    width: 30,
                    aspect: 1.125 / 1.687,
                }
        }
        this.setState({crop: newCrop})
    }

    async saveImage() {
        let newName = this.state.nameArray[this.state.index];
        const newImage = await this.getCroppedImg(
            this.imageRef,
            this.state.crop,
            newName,
        );
        await this.overWriteImage(this.state.index, newName, newImage);
        this.disableSize();
        this.resetContent();
        window.scrollTo(0, 0);
    }

    resetContent() {
        if (this.state.index >= this.state.src.length - 1) {
            if (this.state.type === "face") {
                this.setState({
                    index: 0, type: "player", crop: {
                        unit: '%',
                        width: 30,
                        aspect: 2 / 3
                    }, name: this.state.nameArray[0].substr(0, this.state.nameArray[0])
                })
            } else {
                this.setState({showDownload: true})
            }
        } else {
            let newIndex = this.state.index + 1;
            this.setState({index: newIndex, name: this.state.nameArray[newIndex]})
            if (this.state.type === "face")
                this.resetCrop(this.state.size);
            else
                this.setState({
                    crop: {
                        unit: '%',
                        width: 30,
                        aspect: 2 / 3,
                    }
                })
            }
    }

    changeName(event) {
        let newArr = [...this.state.nameArray];
        newArr[this.state.index] = event.target.value;
        this.setState({nameArray: newArr})
    }

    async overWriteImage(ind, name, image) {
        if (this.state.type === "face") {
            this.faceCardArray.push({name: name, image: image})
        } else {
            this.playerCardArray.push({name: name, image: image})
        }
    }

    disableSize() {
        let radios = document.getElementsByName("size");
        radios.forEach(radio => radio.disabled = true);
    }

    changeSize(event) {
        this.setState({size: event.target.value})
        this.resetCrop(event.target.value)
    }

    getCroppedImg(image, crop, fileName, display = true) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        if (display) {
            return new Promise((resolve, reject) => {
                resolve(canvas.toDataURL());
            });
        } else {
            return canvas.toDataURL("image/jpeg");
        }
    }

    render() {
        if (!this.state.showDownload) {
            return (
                <div>
                    <h3>Crop {this.state.type} cards</h3>

                    <div className="edit-content">
                    {this.state.type === "face" && (
                        <section className={"side-controls"}>

                        <div className={"side-container"}>
                            <h3 className="section-title">Set board size</h3>
                            <div className="radio-container" onChange={this.changeSize.bind(this)}>
                                <input className="option-select" type="radio" value="small" name="size"
                                       defaultChecked={this.state.size === "small"} id="small"/> <label htmlFor="small" className="option-text">Small</label>
                                <input className="option-select" type="radio" value="medium" name="size"
                                       defaultChecked={this.state.size === "medium"}  id="medium"/> <label htmlFor="medium" className="option-text">Medium</label>
                                <input className="option-select" type="radio" value="large" name="size"
                                       defaultChecked={this.state.size === "large"}  id="large"/> <label htmlFor="large" className="option-text">Large</label>
                            </div>
                            {this.state.index === 0 && (
                                <p>Choose the size of card that will fit your board. For more information check out the
                                    how to</p>
                            )}

                            <h3 className="section-title">Name this character</h3>
                            <input type="text" id="name" className="enter-name"
                            value={this.state.nameArray[this.state.index]}
                            onChange={this.changeName.bind(this)}/>
                        </div>
                        </section>

                        )}
                    {/*{this.state.type === "player" && (*/}
                    {/*    <div className={"side-container"}>*/}
                    {/*        <div>*/}
                    {/*            <p>{this.state.nameArray[this.state.index]}</p>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}

                    {/*)}*/}
                    <section className={"edit-container"}>

                        <div className="edit-section">

                            {this.state.src[this.state.index] && this.state.crop && (

                                <div className={"react-cropped-container"}>
                                    <p className="section-title">Original Image</p>
                                    <ReactCrop
                                        src={this.state.src[this.state.index].image}
                                        crop={this.state.crop}
                                        ruleOfThirds
                                        onImageLoaded={this.onImageLoaded}
                                        onComplete={this.onCropComplete}
                                        onChange={this.onCropChange}
                                    />
                                </div>
                            )}
                            {this.state.croppedImageUrl && (
                                <div className={"react-cropped-container"}>
                                    <p className="section-title">Your Card</p>
                                    <img alt="Crop" className={"cropped-image " + (this.state.size) +" " + (this.state.type)}
                                         src={this.state.croppedImageUrl}/>
                                    <div className={"tile-label " + (this.state.type)}><p className={"tile-font tile-text " + (this.state.type)}>{this.state.nameArray[this.state.index]}</p></div>
                                </div>
                            )}
                        </div>



                    </section>
                </div>
                    {/*<button onClick={() => this.saveImage()} className="btn-primary" name="getNext">Next</button>*/}
                    <Button variant="success" className="btn-primary  file-upload-btn" onClick={() => this.saveImage()}
                            name="getNext">Continue</Button>
                </div>
            );
        } else {
            return (
                <DownloadPage faceCards={this.faceCardArray} playerCards={this.playerCardArray} size={this.state.size}/>
            );
        }
    }
}


export default EditPhotos;


