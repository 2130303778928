import React, {Component} from "react";
import {StyleSheet, Document, PDFDownloadLink, Font} from '@react-pdf/renderer';
import font from "./Fonts/obelixpro-cyr-webfont.ttf"

import GuessWhoBoard from "./GuessWhoBoard";
import Slide from "@material-ui/core/Slide";

Font.register({ family: 'obelixproregular', format: "truetype", src: font });


const styles = StyleSheet.create({
    text: {
        fontFamily: 'obelixproregular',
    },
    doc: {
        flexDirection: 'row',
        backgroundColor: '#E4E4E4',
        width: '100%',
        height: '100vh',
    },
});


const DownloadSmallTiles = (sources) => (
    <Document style={styles.doc}>
        <GuessWhoBoard tileType="small" images={sources.sources} size={sources.size}/>
    </Document>
)

const DownloadLargeTiles = (sources) => (
    <Document style={styles.doc}>
        <GuessWhoBoard tileType="large" images={sources.sources} />
    </Document>
)


class DownloadPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            firstLoaded: false
        }
    }

    returnState (loading){
        if(!this.state.firstLoaded && !loading){
            this.setState({firstLoaded: true})
        }
        return loading ? 'Loading document...' : 'Download now!'
    }

render() {
    return (
        <Slide direction="left" in={true} out={true} mountOnEnter unmountOnExit>
            <div>
                <h3>Download your files</h3>
                <div className={"download-section"}>
                <h3 className="section-title">Player cards</h3>
                <p>Print this document once</p>
                <PDFDownloadLink document={<DownloadLargeTiles sources={this.props.playerCards}/>} fileName="largeTiles.pdf">
                    {({loading}) => (this.returnState(loading))}
                </PDFDownloadLink>
                </div>
                <div className={"download-section"}>
                <h3 className="section-title">Face Cards</h3>
                <p>Print this document twice</p>
                {!this.state.firstLoaded && (
                        <p>Loading document...</p>
                )}
                {this.state.firstLoaded && (
                    <PDFDownloadLink document={<DownloadSmallTiles sources={this.props.faceCards} size={this.props.size}/>}
                                     fileName="smallTiles.pdf">
                        {({loading}) => (loading ? 'Loading document...' : 'Download now!')}
                    </PDFDownloadLink>
                )}
                </div>
                {/*<PDFViewer style={styles.doc}>*/}
                {/*     <Document style={styles.doc}>*/}
                {/*         <GuessWhoPreviewBoard />*/}
                {/*     </Document>*/}
                {/* </PDFViewer>*/}

            </div>
        </Slide>
    );
}
}

export default DownloadPage;
